<template>
  <div>
    <Card>
      <template #title>Запрос на удаление ИЧ №{{ data.id }}</template>
      <template #content>
        <p><strong>Создан: </strong>{{ formatDate(data.createdAt) }}</p>
        <p v-if="data.updatedAt !== null"><strong>Обновлен: </strong>{{ formatDate(data.updatedAt) }}</p>
        <p v-if="data.completedAt !== null"><strong>Завершен: </strong>{{ formatDate(data.completedAt) }}</p>
        <Divider></Divider>
        <p><strong>Бюро: </strong>{{ $filters.translate($store, 'deleteInformationPartBureaus', data.bureau) }}</p>
        <p>
          <strong>Статус: </strong>
          <StatusChip
            :value="data.status"
            compendium="processStatuses"
          />
        </p>
        <p v-if="data.filename !== null"><strong>Имя файла: </strong>{{ data.filename }}</p>
      </template>
      <template #footer>
        <div class="grid justify-content-between">
          <div>
            <SplitButton v-if="actions.length > 0" label="Действия c запросом" icon="pi pi-bolt" :model="actions"></SplitButton>
          </div>
          <div>
            <Button @click="refresh" icon="pi pi-refresh" class="p-button-rounded p-button-outlined"></Button>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StatusChip from "@/components/Common/StatusChip.vue";
import FilterDateService from "@/service/FilterDateService";
import DeleteInformationPartActions from "@/helpers/actions/deleteInformationPartActions";

export default {
  name: 'DeleteInformationPartCard',
  components: {StatusChip},
  props: {
    data: {
      type: Object,
      required: true
    },
    afterAction: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      actions: []
    }
  },
  mounted() {
    this.actions = this.generateItems(this.data)
  },
  methods: {
    generateItems(data) {
      const actions = new DeleteInformationPartActions(data);
      actions.afterResend = this.afterAction;

      return actions.getItems();
    },
    formatDate(date) {
      return FilterDateService.dateToString(date)
    },
    refresh() {
      if (this.afterAction !== null) {
        this.afterAction();
      }
    }
  }
}
</script>