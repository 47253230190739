<template>
  <div v-if="loading" class="flex col-12 justify-content-center" style="position: absolute;top: 50%;transform: translate(0, -50%);">
    <ProgressSpinner></ProgressSpinner>
  </div>
  <div v-if="!loading && informationPart !== null">
    <div class="grid">
      <div class="col-12 lg:col-4">
        <DeleteInformationPartCard
          :data="informationPart"
          :after-action="this.refresh"
        />
      </div>
      <div class="col-12 lg:col-8">
        <DeleteInformationPartApplicationsTable
          :default-data="{ deleteInformationPartId: informationPart.id }"
          title="Заявки в запросе"
        />
      </div>
    </div>
    <div v-if="!loading && error !== null">
      <Error :message="error"/>
    </div>
  </div>
</template>

<script>
import Error from "@/components/Error.vue";
import DeleteInformationPartCard from "@/components/InformationPart/DeleteInformationPartCard.vue";
import InformationPartService from "@/service/InformationPartService";
import ResponseService from "@/service/ResponseService";
import DeleteInformationPartApplicationsTable from "@/components/tables/DeleteInformationPartApplicationsTable.vue";

export default {
  name: 'DeleteInformationPartView',
  components: {DeleteInformationPartApplicationsTable, DeleteInformationPartCard, Error},
  data() {
    return {
      informationPart: null,
      id: null,
      loading: true,
      error: null
    }
  },
  mounted() {
    this.id = parseInt(this.$router.currentRoute.value.params.id);
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      InformationPartService.get(this.id).then((res) => {
        this.informationPart = res.data.deleteInformationPart
      })
      .catch((err) => {
        ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
        this.error = 'Произошла ошибка'
      })
      .finally(() => {
        this.loading = false;
      })
    },
    refresh() {
      this.getData();
    }
  }
}
</script>

